import React, {
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from "react";

import EmailLogin from "./LoginTypes/EmailLogin";
import SMSLogin from "./LoginTypes/SMSLogin";
import TuttiLogin from "./LoginTypes/TuttiLogin";
import Div from "../../../reusable/containers/Div";
import useSPTranslation from "../../../reusable/useSPTranslation";
import { Alert } from "@mui/material";
import ForgotPage from "./ForgotPage";

export type LoginProps = {
  loggingIn: boolean;
  setLoggingIn: React.Dispatch<React.SetStateAction<boolean>>;
  error: (error: string | number | undefined, custom?: string) => void;
};

type SwitchButtonProps = {
  onClick: () => void;
  icon: string | ReactNode;
  text: string | ReactNode;
};
const SwitchButton: React.FC<SwitchButtonProps> = ({ onClick, text, icon }) => (
  <div
    className="btn btn-secondary"
    style={{
      display: "flex",
      width: "100%",
      borderRadius: "0.375rem",
      padding: "10px 20px",
      gap: "10px",
      alignItems: "center",
    }}
    onClick={onClick}
  >
    {typeof icon === "string" ? <em className={icon} /> : icon}
    <span>{text}</span>
  </div>
);

type ExtraButtonProps = {
  onClick: () => void;
  icon: string;
  text: string;
};
const ExtraButton: React.FC<ExtraButtonProps> = ({ onClick, text, icon }) => (
  <div className="card" style={{ marginBottom: 0 }}>
    <div
      className="btn btn-blank"
      style={{
        padding: "10px 15px",
        border: "none",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "15px",
          width: "100%",
          maxWidth: "200px",
          margin: "0 auto",
        }}
      >
        <em className={icon} />
        <span>{text}</span>
      </div>
    </div>
  </div>
);

enum LoginMethod {
  TUTTI,
  EMAIL,
  SMS,
}

const Login: React.FC = () => {
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(
    LoginMethod.TUTTI
  );
  const [loggingIn, setLoggingIn] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [forgotEmailPass, setForgotEmailPass] = useState<boolean>(false);

  const moreOptions = useMemo(() => [LoginMethod.SMS], []);
  const { t } = useSPTranslation();

  useEffect(() => {
    if (window.location.pathname !== "/") {
      window.history.replaceState(null, "", "/");
    }
  }, []);

  const ERROR_MESSAGES: Record<string | number, string> = useMemo(
    () => ({
      "": "",
      "400": t("components.login.errors.400"),
      "auth/user-not-found": t("components.login.errors.auth/user-not-found"),
      "auth/wrong-password": t("components.login.errors.auth/wrong-password"),
      "auth/invalid-phone-number": t(
        "components.login.errors.auth/invalid-phone-number"
      ),
      "auth/invalid-email": t("components.login.errors.auth/invalid-email"),
      "auth/unauthorized": t("components.login.errors.auth/unauthorized"),
      "validation/missing_data": t(
        "components.login.errors.validation/missing_data"
      ),
      "invalid-argument": t("components.login.errors.invalid-argument"),
    }),
    [t]
  );

  const error: LoginProps["error"] = useCallback(
    (code, custom) => {
      if (!code) {
        setErrorMessage(undefined);
        return;
      }

      if (custom) {
        setErrorMessage(code ? `${code} ${custom}` : custom);
        return;
      }

      setErrorMessage(
        ERROR_MESSAGES[code] ||
          t(`components.login.errors.${code}`, "") ||
          t("components.login.errors.default")
      );
    },
    [ERROR_MESSAGES, t]
  );

  const changeLoginMethod = useCallback((state: LoginMethod) => {
    setLoginMethod(state);
    setShowMoreOptions(false);
  }, []);

  useEffect(() => {
    if (moreOptions.includes(loginMethod)) {
      setShowMoreOptions(false);
    }
  }, [loginMethod, moreOptions]);

  if (forgotEmailPass) {
    return <ForgotPage setForgotPass={setForgotEmailPass} />;
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        maxHeight: "100%",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "335px", width: "60vw" }}>
        <div className="text-center bg-gray-darker">
          <img
            className="block-center rounded img-fluid px-5 py-3"
            src="img/logo-tutti.svg"
            alt="Tuttis logo"
          />
        </div>
        <div
          className="row"
          style={{
            marginTop: "-35px",
            display: "flex",
            flexDirection: "row",
            margin: 0,
          }}
        >
          <div
            className="col"
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "550px",
              padding: 0,
            }}
          >
            <div
              className="card"
              style={{ height: "100%", marginBottom: 0, width: "100%" }}
            >
              {showMoreOptions ? (
                <div className="card-body">
                  {/*Login swappers*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <SwitchButton
                      onClick={() => changeLoginMethod(LoginMethod.TUTTI)}
                      icon={"fas fa-chevron-right"}
                      text={t("components.login.switchButtons.tutti")}
                    />

                    <SwitchButton
                      onClick={() => changeLoginMethod(LoginMethod.EMAIL)}
                      icon={"fas fa-at"}
                      text={t("components.login.switchButtons.email")}
                    />

                    <SwitchButton
                      onClick={() => changeLoginMethod(LoginMethod.SMS)}
                      icon={"fas fa-mobile"}
                      text={t("components.login.switchButtons.sms")}
                    />
                  </div>
                </div>
              ) : (
                <div className="card-body">
                  <div>
                    {!!errorMessage && (
                      <Alert
                        variant="filled"
                        severity="warning"
                        onClose={() => setErrorMessage("")}
                      >
                        {errorMessage}
                      </Alert>
                    )}

                    {/*Login option components*/}
                    {loginMethod === LoginMethod.TUTTI && (
                      <TuttiLogin
                        loggingIn={loggingIn}
                        setLoggingIn={setLoggingIn}
                        error={error}
                      />
                    )}
                    {loginMethod === LoginMethod.EMAIL && (
                      <EmailLogin
                        loggingIn={loggingIn}
                        setLoggingIn={setLoggingIn}
                        setForgotPass={setForgotEmailPass}
                        error={error}
                      />
                    )}
                    {loginMethod === LoginMethod.SMS && (
                      <SMSLogin
                        loggingIn={loggingIn}
                        setLoggingIn={setLoggingIn}
                        error={error}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={{ width: "100%", marginTop: "15px" }}>
              {showMoreOptions ? (
                <ExtraButton
                  onClick={() => setShowMoreOptions((old) => !old)}
                  icon={"fas fa-arrow-left"}
                  text={t("common.back")}
                />
              ) : (
                <Div style={{ gap: "5px" }}>
                  {loginMethod !== LoginMethod.TUTTI && (
                    <ExtraButton
                      onClick={() => changeLoginMethod(LoginMethod.TUTTI)}
                      icon={"fa fa-chevron-right"}
                      text={t("components.login.switchButtons.tutti")}
                    />
                  )}
                  {loginMethod !== LoginMethod.EMAIL && (
                    <ExtraButton
                      onClick={() => changeLoginMethod(LoginMethod.EMAIL)}
                      icon={"fas fa-at"}
                      text={t("components.login.switchButtons.email")}
                    />
                  )}
                  {loginMethod !== LoginMethod.SMS && (
                    <ExtraButton
                      onClick={() => changeLoginMethod(LoginMethod.SMS)}
                      icon={"fa fa-chevron-right"}
                      text={t("components.login.switchButtons.sms")}
                    />
                  )}

                  <ExtraButton
                    onClick={() => setShowMoreOptions((old) => !old)}
                    icon={"fas fa-key"}
                    text={t(
                      "components.login.more_options",
                      "Andre innloggins metoder"
                    )}
                  />
                </Div>
              )}
            </div>
          </div>
          {/*<Col lg={5} style={{ padding: '0 0 0 3px', display: 'flex' }}>
                          <Card
                              style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                  width: '100%',
                              }}
                          >
                              <QRLogin loggingIn={loggingIn} setLoggingIn={setLoggingIn} error={error} />
                          </Card>
                      </Col>*/}
        </div>
      </div>
    </div>
  );
};

export default Login;
