import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoginProps } from "../Login";
import { cipherTuttiLogin } from "../helpers/cipherTuttiLogin";
import { firebase } from "@styreportalen/common";
import decipherTuttiLoginToken from "../helpers/decipherTuttiLoginToken";
import { decipherTuttiResponse } from "../helpers/deciperTuttiResponse";
import useTuttiLoginRequestData, {
  TuttiQRMode,
} from "../hooks/useTuttiLoginRequestData";
import {
  TuttiLoginListenerStatus,
  TuttiLoginRequestMode,
  UserSignInRequestMode,
} from "@styreportalen/common/lib/users/tutti_login/tuttilogin";
import LoginMethodInfo from "../components/LoginMethodInfo";
import DialCodePicker from "../../../../reusable/DialCodePicker";
import { Auth, DB, Functions } from "../../../firebase/firebase";
import { InputLabel } from "@mui/material";
import useSPTranslation from "../../../../reusable/useSPTranslation";

const TuttiLogin: React.FC<LoginProps> = ({
  loggingIn,
  setLoggingIn,
  error,
}) => {
  const showCaptcha = useRef(Math.floor(Math.random() * 5) === 0);

  const [selectedCountry, setSelectedCountry] = useState({
    label: "NO",
    value: "+47",
  });
  const [mobileNumber, setMobileNumber] = useState("");
  const [answer, setAnswer] = useState<number>();
  const [creatingRequest, setCreatingRequest] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(showCaptcha.current);
  const [step, setStep] = useState(0);
  const [attempts, setAttempts] = useState(0);

  const { requestData } = useTuttiLoginRequestData(TuttiQRMode.NOTIFICATION);

  const hasLoggedIn = useRef(false);

  const { t } = useSPTranslation();

  const hasInitializedCaptcha = useRef(false);
  useEffect(() => {
    if (attempts >= 3) {
      showCaptcha.current = true;
    }

    if (!hasInitializedCaptcha.current && step === 0 && showCaptcha.current) {
      (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container-tutti",
        {
          size: "normal",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            setButtonDisabled(false);
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
            setButtonDisabled(true);
          },
        }
      );
      (window as any).recaptchaVerifier.render().then(function (widgetId: any) {
        (window as any).recaptchaWidgetId = widgetId;
      });
    }

    return () => {
      (window as any).recaptchaVerifier = undefined;
      const captchaContainer = document.getElementById(
        "recaptcha-container-tutti"
      );
      if (captchaContainer) {
        captchaContainer.innerHTML = "";
      }
      hasInitializedCaptcha.current = false;
    };
  }, [step, attempts]);

  const hasMadeRequest = useRef(false);
  const sendPrompt = useCallback(() => {
    error(undefined);
    if (buttonDisabled || loggingIn) {
      return;
    }

    if (!requestData) {
      error("validation/missing_data");
      return;
    }

    setButtonDisabled(true);
    setAttempts((old) => old + 1);
    showCaptcha.current = false;

    const { secret, token, deviceMeta } = requestData;

    setCreatingRequest(true);

    if (!mobileNumber) {
      error("auth/invalid-phone-number");
      return;
    }

    return Functions.httpsCallable("users-tuttilogin-request")(
      cipherTuttiLogin({
        secret,
        mode: TuttiLoginRequestMode.NOTIFICATION_TW,
        request: token,
        mobile_number: selectedCountry.value + mobileNumber,
        device_meta: deviceMeta,
      })
    )
      .then((res) => {
        if (typeof res.data !== "number") {
          return;
        }

        setAnswer(res.data);
      })
      .then(() => {
        hasMadeRequest.current = true;
        setStep(1);
      })
      .catch((reason) => {
        error(reason.code);
      })
      .finally(() => {
        setCreatingRequest(false);

        setTimeout(() => setButtonDisabled(false), 5000);
      });
  }, [
    buttonDisabled,
    error,
    loggingIn,
    mobileNumber,
    requestData,
    selectedCountry.value,
  ]);

  const hasSetListener = useRef(false);
  useEffect(() => {
    let snapshotListener = () => {};
    if (
      !hasSetListener.current &&
      requestData &&
      hasMadeRequest.current &&
      step === 1
    ) {
      console.log("sets listener");
      const { token, secret, deviceMeta } = requestData;

      snapshotListener = DB.collection("tutti_login")
        .doc(token)
        .onSnapshot((res) => {
          const data = res.data();

          if (hasLoggedIn.current && !data) {
            snapshotListener();
            return;
          }

          if (data?.status === TuttiLoginListenerStatus.COMPLETED) {
            Functions.httpsCallable("users-tuttilogin-get")(
              cipherTuttiLogin({
                mode: UserSignInRequestMode.NOTIFICATION,
                secret,
                request: token,
                device_meta: deviceMeta,
              })
            )
              .then((res) => {
                console.log(res);
                const data = decipherTuttiResponse(res.data);

                if (!data?.token) {
                  error("internal");
                }

                return Auth.signInWithCustomToken(
                  decipherTuttiLoginToken(data.token, secret)
                )
                  .then(() => {
                    hasLoggedIn.current = true;
                  })
                  .catch((e) => error(e.code))
                  .finally(() => setLoggingIn(false));
              })
              .catch((e) => error(e.code));
          }

          hasSetListener.current = true;
        });
    }

    return () => {
      if (
        requestData &&
        hasSetListener.current &&
        hasLoggedIn.current &&
        hasMadeRequest.current
      ) {
        snapshotListener();
      }
    };
  }, [error, requestData, setLoggingIn, step]);

  switch (step) {
    case 0:
      return (
        <div>
          <LoginMethodInfo title={t("components.login.tutti.text")}>
            <span>{t("components.login.tutti.info")}</span>
          </LoginMethodInfo>

          <div className="form-group">
            <DialCodePicker
              default_dialCode={"+47"}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          </div>

          <div className="form-group">
            <input
              className="form-control"
              type="tel"
              disabled={loggingIn}
              value={mobileNumber}
              onChange={({ target: { value } }) =>
                setMobileNumber(
                  value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                )
              }
              onKeyPress={(key) => key.charCode === 13 && sendPrompt()}
              placeholder={t("common.mobile_number")}
            />
          </div>

          <div
            className="form-group"
            style={{
              display: showCaptcha.current ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              id="recaptcha-container-tutti"
              className="mb-3 mx-auto"
              style={{ display: step === 0 ? undefined : "none" }}
            />
          </div>

          <div
            className="form-group"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              className={`btn btn-primary w-100 ${
                buttonDisabled ? "disabled" : ""
              }`}
              style={{ display: "block" }}
              onClick={sendPrompt}
            >
              {t("components.login.tutti.send")}{" "}
              {(creatingRequest || loggingIn) && (
                <em className="fas fa-circle-notch fa-spin" />
              )}
            </div>
          </div>
        </div>
      );
    case 1:
      return (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px",
              textAlign: "center",
            }}
          >
            <div className="form-group">
              <InputLabel>
                {t("components.login.tutti.number_text", "Ditt tall er")}
              </InputLabel>

              <strong style={{ fontSize: "2.5rem", display: "block" }}>
                {answer}
              </strong>
            </div>
            <div className="form-group">
              <div
                className={`btn btn-primary w-100 ${
                  buttonDisabled ? "disabled" : ""
                }`}
                style={{
                  display: "block",
                }}
                onClick={sendPrompt}
              >
                {t("components.login.tutti.retry")}
              </div>
            </div>
            <div className="form-group">
              <p style={{ maxWidth: "300px" }}>
                {t("components.login.tutti.notification_info")}
              </p>
            </div>
            <div className="form-group">
              <div className="btn btn-warning" onClick={() => setStep(0)}>
                {t("common.close")}
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default TuttiLogin;
