import React, { memo } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

// Vendor dependencies
import "./Vendor";

// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import "./styles/custom.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default memo(App);
